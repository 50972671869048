import React, { useMemo } from 'react'
import { render } from 'react-dom'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import 'reset-css'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import Survey from './Survey'
import Result from './Result'

firebase.initializeApp({
  apiKey: 'AIzaSyCUN5H7ymGpEgUzpOCBq7Iube_yLOaXZPs',
  authDomain: 'meru-mind-check.firebaseapp.com',
  databaseURL: 'https://meru-mind-check.firebaseio.com',
  projectId: 'meru-mind-check',
})

const REDIRECT_PARAM = /[\?\&]r=([^&]+)/
const getRedirectPath = () => {
  const m = REDIRECT_PARAM.exec(window.location.search)
  if (m && m[1]) {
    return decodeURIComponent(m[1])
  }
  console.error('?r=... parameter is missing from the URL')
  return ''
}

const App: React.FC = () => {
  const redirectPath = useMemo(getRedirectPath, [])
  return (
    <Router>
      <Routes>
        <Route path="/:typeformID" element={<Survey />} />
        <Route path="result/:responseID" element={<Result redirectPath={redirectPath} />} />
      </Routes>
    </Router>
  )
}

render(<App />, document.getElementById('root'))
