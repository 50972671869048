import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import firebase from 'firebase/app'
import FullScreenSpinner from './FullScreenSpinner'

type TypeformResponse = {
  id: string
  receivedAt: Date
  data: any
}

const io = {
  toFirestore: ({ id, ...rest }: { id: string } & TypeformResponse) => rest,
  fromFirestore: (snap: firebase.firestore.DocumentSnapshot): TypeformResponse => {
    const doc = snap.data()!
    return {
      id: snap.id,
      receivedAt: new Date(doc.receivedAt.seconds * 1000),
      data: doc.data,
    }
  },
}

const useTypeformResponse = (id: string) => {
  const db = firebase.firestore()
  const [val, setVal] = useState<TypeformResponse | undefined>()
  useEffect(() => {
    return db
      .collection('typeformResponses')
      .doc(id)
      .withConverter(io)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setVal(doc.data())
        }
      })
  }, [id])
  return val
}

const PHQ9_GAD7_OPTIONS = ['Not at all', 'Several days', 'More than half of the days', 'Nearly every day']
const questionMapPHQ9 = new Map([
  ['PHQ9_QUESTION_1', 'phq9_1'],
  ['PHQ9_QUESTION_2', 'phq9_2'],
  ['PHQ9_QUESTION_3', 'phq9_3'],
  ['PHQ9_QUESTION_4', 'phq9_4'],
  ['PHQ9_QUESTION_5', 'phq9_5'],
  ['PHQ9_QUESTION_6', 'phq9_6'],
  ['PHQ9_QUESTION_7', 'phq9_7'],
  ['PHQ9_QUESTION_8', 'phq9_8'],
  ['PHQ9_QUESTION_9', 'phq9_9'],
])
const questionMapGAD7 = new Map([
  ['GAD7_QUESTION_1', 'gad7_1'],
  ['GAD7_QUESTION_2', 'gad7_2'],
  ['GAD7_QUESTION_3', 'gad7_3'],
  ['GAD7_QUESTION_4', 'gad7_4'],
  ['GAD7_QUESTION_5', 'gad7_5'],
  ['GAD7_QUESTION_6', 'gad7_6'],
  ['GAD7_QUESTION_7', 'gad7_7'],
])

type ScoreAnswer = { question: string; answer: number }

const calculateScore = (responses: { answer: string; ref: string | null }[], references: Map<string, string>) => {
  const result = responses.reduce(
    ({ score, answers }, response) => {
      const question = response.ref && references.get(response.ref)
      if (question) {
        const questionScore = PHQ9_GAD7_OPTIONS.indexOf(response.answer)
        if (questionScore === -1) {
          throw new Error(`PHQ9/GAD7 question answer could not be found for ${response.ref}`)
        }
        return {
          score: score + questionScore,
          answers: [...answers, { question, answer: questionScore }],
        }
      }
      return {
        score,
        answers,
      }
    },
    { score: 0, answers: [] as ScoreAnswer[] }
  )
  if (result.answers.length < references.size) {
    throw new Error('Questions were missing from PHQ9/GAD7')
  }
  return result
}

const getQuery = (response: TypeformResponse) => {
  const { responses } = response.data

  const data: Record<string, number> = {}
  try {
    const phq9 = calculateScore(responses, questionMapPHQ9)
    data.phq9 = phq9.score
    phq9.answers.forEach(({ question, answer }) => {
      data[question] = answer
    })
  } catch (err) {
    console.error(err)
  }
  try {
    const gad7 = calculateScore(responses, questionMapGAD7)
    data.gad7 = gad7.score
    gad7.answers.forEach(({ question, answer }) => {
      data[question] = answer
    })
  } catch (err) {
    console.error(err)
  }

  return Object.entries(data)
    .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
    .join('&')
}

const Result: React.FC<{ redirectPath: string }> = ({ redirectPath }) => {
  const { responseID: id } = useParams()
  const response = useTypeformResponse(id)

  useEffect(() => {
    if (response) {
      let query = getQuery(response)
      if (query) {
        query = `&${query}`
      }
      window.location.href = `https://www.meruhealth.com/${redirectPath}${
        redirectPath.includes('?') ? '&' : '?'
      }d=${id}${query}`
    }
  }, [response])

  return <FullScreenSpinner />
}

export default Result
