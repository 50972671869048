import React, { useRef, useCallback, useMemo } from 'react'
import firebase from 'firebase/app'
import { useNavigate } from 'react-router-dom'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import { useParams } from 'react-router'

const useFirebaseID = () => {
  const db = firebase.firestore()
  const ref = useRef(db.collection('typeformResponses').doc().id)
  return ref.current
}

const Survey: React.FC = () => {
  const id = useFirebaseID()
  const { typeformID } = useParams()
  const navigate = useNavigate()
  const navigateToResult = useCallback(() => {
    navigate(`/result/${id}`, { replace: true })
  }, [navigate, id])

  return (
    <ReactTypeformEmbed
      url={`https://meruhealth.typeform.com/to/${typeformID}?t=${id}`}
      onSubmit={navigateToResult}
      style={{ width: '100%', height: '100%' }}
    />
  )
}

export default Survey
