import React from 'react'

const styles = `
#root-spinner {
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  fill: currentColor;
}
.dviiem svg {
  display: block;
}
.dviiem rect {
  fill: currentColor;
  opacity: 0.2;
  -webkit-animation: 0.8s lcLMJi infinite;
  animation: 0.8s lcLMJi infinite;
}
.dviiem rect:nth-child(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.dviiem rect,
.dviiem rect:nth-child(1) {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.dviiem rect,
.dviiem rect:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.dviiem rect,
.dviiem rect:nth-child(3) {
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}
.dviiem rect,
.dviiem rect:nth-child(4) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.dviiem rect,
.dviiem rect:nth-child(5) {
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.dviiem rect,
.dviiem rect:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.dviiem rect,
.dviiem rect:nth-child(7) {
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}
.dviiem rect,
.dviiem rect:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.dviiem rect,
.dviiem rect:nth-child(9) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}
.dviiem rect,
.dviiem rect:nth-child(10) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.dviiem rect,
.dviiem rect:nth-child(11) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}
.dviiem rect,
.dviiem rect:nth-child(12) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.dviiem rect,
.dviiem rect:nth-child(13) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
.dviiem rect,
.dviiem rect:nth-child(14) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.dviiem rect,
.dviiem rect:nth-child(15) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
data-styled.g13[id='SpinnerSvg-jjs2a1-0'] {
  content: 'dviiem,';
}
.kRXJKh {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
data-styled.g14[id='LoaderLayout-sc-1eu50fy-0'] {
  content: 'kRXJKh,';
}
.dPvDlF {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}
data-styled.g15[id='LoaderWrapper-sc-1eu50fy-1'] {
  content: 'dPvDlF,';
}
.dBkvUm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
data-styled.g16[id='SpinnerWrapper-sc-1eu50fy-2'] {
  content: 'dBkvUm,';
}
@-webkit-keyframes lcLMJi {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
}
@keyframes lcLMJi {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  75% {
    opacity: 0.2;
  }
}
data-styled.g17[id='sc-keyframes-lcLMJi'] {
  content: 'lcLMJi,';
}
`
const Spinner: React.FC = () => (
  <>
    <style>{styles}</style>
    <div id="root-spinner" style={{ color: 'rgb(68, 68, 76)' }}>
      <div className="LoaderLayout-sc-1eu50fy-0 kRXJKh">
        <div className="LoaderWrapper-sc-1eu50fy-1 dPvDlF">
          <div className="SpinnerWrapper-sc-1eu50fy-2 dBkvUm">
            <span color="currentColor" className="SVGInline SpinnerSvg-jjs2a1-0 dviiem">
              <svg
                className="SVGInline-svg SpinnerSvg-jjs2a1-0-svg dviiem-svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>loader</title>
                <g fill="#2F2F26" fillRule="evenodd">
                  <rect transform="rotate(22.5 26.506 4.294)" x="25.506" y="1.294" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(45 32.02 7.98)" x="31.021" y="4.979" width="2" height="6" rx="1"></rect>
                  <rect
                    transform="rotate(-112.5 35.706 13.494)"
                    x="34.706"
                    y="10.494"
                    width="2"
                    height="6"
                    rx="1"
                  ></rect>
                  <rect transform="rotate(-90 37 20)" x="36" y="17" width="2" height="6" rx="1"></rect>
                  <rect
                    transform="rotate(-67.5 35.706 26.506)"
                    x="34.706"
                    y="23.506"
                    width="2"
                    height="6"
                    rx="1"
                  ></rect>
                  <rect transform="rotate(-45 32.02 32.02)" x="31.021" y="29.021" width="2" height="6" rx="1"></rect>
                  <rect
                    transform="rotate(-22.5 26.506 35.706)"
                    x="25.506"
                    y="32.706"
                    width="2"
                    height="6"
                    rx="1"
                  ></rect>
                  <rect x="19" y="34" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(22.5 13.494 35.706)" x="12.494" y="32.706" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(45 7.98 32.02)" x="6.979" y="29.021" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(-112.5 4.294 26.506)" x="3.294" y="23.506" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(-90 3 20)" x="2" y="17" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(-67.5 4.294 13.494)" x="3.294" y="10.494" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(-45 7.98 7.98)" x="6.979" y="4.979" width="2" height="6" rx="1"></rect>
                  <rect transform="rotate(-22.5 13.494 4.294)" x="12.494" y="1.294" width="2" height="6" rx="1"></rect>
                  <rect x="19" width="2" height="6" rx="1"></rect>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Spinner
